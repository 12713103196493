// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/alessio.masucci/Code/yoox/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brand-book-js": () => import("/Users/alessio.masucci/Code/yoox/src/pages/brand-book.js" /* webpackChunkName: "component---src-pages-brand-book-js" */),
  "component---src-pages-communication-js": () => import("/Users/alessio.masucci/Code/yoox/src/pages/communication.js" /* webpackChunkName: "component---src-pages-communication-js" */),
  "component---src-pages-index-js": () => import("/Users/alessio.masucci/Code/yoox/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-strategy-js": () => import("/Users/alessio.masucci/Code/yoox/src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/alessio.masucci/Code/yoox/.cache/data.json")

